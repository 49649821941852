.ribbon {
    width: 90px;
    height: 66px;
    overflow: hidden;
    position: absolute;
  }


.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid green;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0 10px 55px;
    background-color: green;
    color: #fff;
    font: 700 7.8px/1 'Lato', sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  /* top left*/
.ribbon-top-left {
    top: 20;
    margin-left: 20px;
    z-index: 1;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  .ribbon-crm-card {
    margin-left: -10px;
  }

  .ribbon-blocked span {
    background-color: #dd3535;
  }

  .ribbon-blocked::after {
    border: 5px solid #b82929;
  }


/* Different Color*/

.ribbon-pending {
  width: 90px;
  height: 66px;
  overflow: hidden;
  position: absolute;
}


.ribbon-pending::after {
position: absolute;
z-index: -1;
content: '';
display: block;
border: 5px solid orange;
}
.ribbon-pending span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0 10px 55px;
  background-color: orange;
  color: #fff;
  font: 700 7.8px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
}
/* top left*/
.ribbon-pending-top-left {
  top: 20;
  margin-left: 20px;
  z-index: 1;
}
.ribbon-pending-top-left::before,
.ribbon-pending-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-pending-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-pending-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-pending-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.ribbon-pending-crm-card {
  margin-left: -10px;
}

.ribbon-pending-blocked span {
  background-color: #dd3535;
}

.ribbon-pending-blocked::after {
  border: 5px solid #b82929;
}