.ribbon {
    width: 90px;
    height: 66px;
    overflow: hidden;
    position: absolute;
  }


.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0 10px 55px;
    background-color: #3498db;
    color: #fff;
    font: 700 8px/1 'Lato', sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  /* top left*/
.ribbon-top-left {
    top: 20;
    margin-left: 20px;
    z-index: 1;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  .ribbon-crm-card {
    margin-left: -10px;
  }

  .ribbon-blocked span {
    background-color: #dd3535;
  }

  .ribbon-blocked::after {
    border: 5px solid #b82929;
  }