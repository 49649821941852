.ribbon-coupon {
    width: 90px;
    height: 66px;
    overflow: hidden;
    position: absolute;
    padding: 10px;
}
.ribbon-coupon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid red;
}
.ribbon-coupon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0 10px 70px;
    background-color: red;
    color: #fff;
    font: 700 8px/1 'Lato', sans-serif;
    text-transform: uppercase;
    text-align: center;
}
  /* top left*/
.ribbon-coupon-top-left {
    top: 20;
    margin-left: 20px;
    z-index: 1;
}
.ribbon-coupon-top-left::before,
.ribbon-coupon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
} */
.ribbon-coupon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-coupon-top-left::after {
    bottom: 0;
    left: 0;

}
.ribbon-coupon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }